import PageMeta from '@components/PageMeta';
import useTranslation from '@helpers/useTranslation';
import DefaultTemplate from '@templates/DefaultTemplate';
import DownloadView from '@views/DownloadView';
import { PageProps } from 'gatsby';
import React, { FC } from 'react';
import { Button } from 'react-bulma-components';
import { DOWNLOAD_PAGE } from '../../constants';

const DownloadMacPage: FC<PageProps> = () => {
  const { t } = useTranslation('pages');

  const downloadUrl = `${DOWNLOAD_PAGE}/mac/latest`;

  return (
    <DefaultTemplate>
      <PageMeta
        description={t('pages:downloadPlatformDescription', { platform: 'Mac' })}
        title={t('pages:downloadPlatform', { platform: 'Mac' })}
      />
      <DownloadView
        DownloadButtons={[
          <Button color="primary" href={downloadUrl} mb={4} renderAs="a" size="medium">
            {t('common:download')}
          </Button>,
        ]}
        platform="mac"
      />
    </DefaultTemplate>
  );
};

export default DownloadMacPage;
